// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBUiroKRRr5XHfFYTMHPWC4vpFYu1cxpsw",
    authDomain: "viperlockstudio.firebaseapp.com",
    databaseURL: "https://viperlockstudio-default-rtdb.firebaseio.com",
    projectId: "viperlockstudio",
    storageBucket: "viperlockstudio.appspot.com",
    messagingSenderId: "477204826202",
    appId: "1:477204826202:web:32d06fef2495e95b083063",
    measurementId: "G-51EE43H7B1"
  };

  export default firebaseConfig;