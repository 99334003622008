import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref as dbRef, onValue } from 'firebase/database'; // Import getDatabase and onValue
import firebaseConfig from './config'; // Import your Firebase configuration

const FirebaseModule = initializeApp(firebaseConfig);
const analytics = getAnalytics(FirebaseModule);
const storage = getStorage(FirebaseModule); // Initialize Firebase Storage
const database = getDatabase(FirebaseModule); // Initialize Firebase Realtime Database


function FirebaseModuleComponent() {
  const [imageUrl, setImageUrl] = useState('');
  const [databaseData, setDatabaseData] = useState({}); // State to hold database data
  function createMarkup(data) {
    let htmlString = '<pre>';
  
    const traverseAndBuildHtml = (obj, indent = '') => {
      if (typeof obj === 'object' && obj !== null) {
        htmlString += '{\n';
        const entries = Object.entries(obj);
        entries.forEach(([key, value], index) => {
          htmlString += `${indent}  ${key}: `;
          if (typeof value === 'object') {
            traverseAndBuildHtml(value, indent + '  ');
            // Check if it's the last property to avoid adding a comma
            if (index !== entries.length - 1) {
              htmlString += ',\n';
            }
          } else {
            // For simplicity, assuming value is a string; adjust as necessary for other types
            htmlString += `${value}`;
            // Check if it's the last property to avoid adding a comma
            if (index !== entries.length - 1) {
              htmlString += ',\n';
            } else {
              htmlString += '\n';
            }
          }
        });
        htmlString += `${indent}}\n`;
      } else {
        // Directly append non-object values (e.g., strings, numbers)
        htmlString += `${obj}\n`;
      }
    };
  
    traverseAndBuildHtml(data);
    htmlString += '</pre>';
  
    return { __html: htmlString };
  }

  useEffect(() => {
    // Fetch image URL
    const imageRef = ref(storage, 'gs://viperlockstudio.appspot.com/VSLogo.png');
    getDownloadURL(imageRef)
      .then((url) => {
        setImageUrl(url); // Set the image URL in the state
        logEvent(analytics, 'image_fetch_success', { imageName: 'VSLogo.png' });
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        logEvent(analytics, 'image_fetch_error', { error: error.message });
      });

    // Fetch data from Firebase Realtime Database
    const databaseRef = dbRef(database, 'website/pages/main/content'); // Adjust the path according to your database structure
    onValue(databaseRef, (snapshot) => {
      const data = snapshot.val();
      setDatabaseData(data); // Set the fetched data to state
    });
  }, []); // The empty array ensures these effects run only once after the initial render

  return (
    <div className="FirebaseModule">
      <header className="FirebaseModule-header">
        <p>
          <img src={imageUrl} className="FirebaseModule-logo" alt="From Firebase Storage" />
        </p>
        <div className="InsertPoint" dangerouslySetInnerHTML={createMarkup(databaseData)} />
      </header>
    </div>
  );
}

export default FirebaseModuleComponent;